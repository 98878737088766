/** @jsx jsx */
import { Flex, Box, Heading, jsx } from "theme-ui"
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image"

import type { Maybe } from "../../graphql-types"

import AguilaButton from "components/AguilaButton"
import MdContent from "components/common/MdContent"

interface AmbassadorsInfoBlockProps {
  //enables sx prop to be passed down from parent
  className?: string
  heading: string
  content: Maybe<string> | undefined
  buttonText?: string
  buttonUrl?: string
  image: IGatsbyImageData
  imageAlt: Maybe<string> | undefined
}

const AmbassadorsInfoBlock = ({
  className,
  heading,
  content,
  image,
  imageAlt,
  buttonText,
  buttonUrl,
}: AmbassadorsInfoBlockProps) => {
  return (
    <Flex
      as="article"
      className={className}
      sx={{
        maxWidth: "1100px",
        flexWrap: "wrap",
        flexDirection: "row",
        ml: "auto",
        mr: "initial",
        alignItems: "center",
        justifyContent: ["end", null, null, "center"],
        pb: [8, null, null, 44],
        ":nth-of-type(even)": {
          alignItems: ["start", null, null, "center"],
          flexDirection: "row-reverse",
          justifyContent: ["flex-end", null, null, "center"],
          ml: "initial",
          mr: "auto",
          ".content": {
            pl: 0,
            pr: [0, null, null, 8],
          },
        },
        ":nth-of-type(2n) .image-container": {
          justifyContent: "flex-start",
        },
        ":nth-of-type(5n) .image-container": {
          justifyContent: "flex-end",
        },
      }}
    >
      <Flex
        className="image-container"
        sx={{
          width: ["100%", null, null, "51%"],
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            mr: 5,
            mb: 5,
            backgroundColor: "text",
            width: ["60%", null, null, "100%"],
            height: "clamp(191px, 40vw, 433px)",
            position: "relative",
            boxShadow: [
              "10px 10px 0px #A18347",
              null,
              null,
              "20px 20px 0px #A18347",
            ],
          }}
        >
          <GatsbyImage
            image={image}
            alt={imageAlt as string}
            sx={{
              width: "100%",
              height: "100%",
              // WebkitFilter: "grayscale(1)",
              // filter: "grayscale(1)",
            }}
            objectFit="cover"
            objectPosition="center center"
          />
        </Box>
      </Flex>
      <Box
        className="content"
        sx={{
          flex: "0 1 49%",
          pl: [2, null, null, 20],
          pr: 0,
          alignSelf: "center",
          my: 8,
        }}
      >
        <Heading as="h1" sx={{ mb: [4, null, null, 5] }}>
          {heading}
        </Heading>

        <MdContent source={content} />

        {buttonText && buttonUrl && (
          <AguilaButton url={buttonUrl} text={buttonText} />
        )}
      </Box>
    </Flex>
  )
}

export default AmbassadorsInfoBlock
