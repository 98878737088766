/** @jsxRuntime classic */
/** @jsx jsx */
import { Grid, Box, Heading, Divider, Flex, jsx } from "theme-ui"

import AguilaButton from "components/AguilaButton"

interface SectionHeadingProps {
  //enables sx prop to be passed down from parent
  className?: string
  children: React.ReactNode
  linkText?: string
  linkUrl?: string
  indented?: boolean
  /**
   * The variant is pulled from text.variant, so you can use hero, heading, title...
   */
  variant?: string
}

const SectionHeading = ({
  className,
  children,
  linkText,
  linkUrl,
  indented = false,
  variant = "heading",
}: SectionHeadingProps) => {
  return (
    <Grid
      className={className}
      sx={{
        gridTemplateColumns:
          "[outer-left] minmax(16px, 1fr) [grid-left] minmax(0, 1188px) [grid-right] minmax(16px, 1fr) [outer-right]",
        gap: 0,
        mb: variant === "hero" ? 4 : [8, null, 12],
      }}
    >
      <Flex
        sx={{
          alignItems: "center",
          gridColumn: "grid-left / outer-right",
        }}
      >
        <Box
          className="titlebox"
          sx={{
            flex: ["1 0 auto", null, "1 0 auto"],
            mr: 16,
            ml: indented ? [0, null, 24] : 0,
            maxWidth: ["60%", null, "510px"],
          }}
        >
          <Heading as="h1" variant={variant}>
            {children}
          </Heading>
        </Box>
        <Divider sx={{ flex: "1 1 auto" }} />
      </Flex>
      {linkText && linkUrl && (
        <AguilaButton
          variant="textOnly"
          text={linkText}
          url={linkUrl}
          sx={{ gridColumn: "grid-left / grid-right" }}
        />
      )}
    </Grid>
  )
}

export default SectionHeading
