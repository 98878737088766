/** @jsxRuntime classic */
/** @jsx jsx */
import React from "react"
import { Box, Container, jsx } from "theme-ui"
import { graphql, PageProps } from "gatsby"

import type { AmbassadorsParentPageQuery } from "../../../graphql-types"

import AmbassadorsInfoBlock from "components/AmbassadorsInfoBlock"
import BannerHero from "components/BannerHero"
import Layout from "components/Layout"
import SectionHeading from "components/SectionHeading"

interface AmbassadorsParentPageProps extends PageProps {
  data: AmbassadorsParentPageQuery
}

const AmbassadorsParentPage = ({
  data,
  ...props
}: AmbassadorsParentPageProps) => {
  return (
    <Layout
      seo={{ metaTitle: "Team Aguila", metaDescription: "Team Aguila" }}
      location={props.location}
    >
      <BannerHero
        heading="Team Aguila"
        subHeading="Aguila Pro Shooters"
        content="Aguila® has established itself among professional shooters as a quality and reliable product. This is backed by a team of brand ambassadors, who believed in the Aguila® advantage well before coming on board as Aguila® shooters."
      />

      <Box
        as="section"
        sx={{
          position: "relative",
          "&::after": {
            content: '""',
            backgroundColor: "text",
            width: "1px",
            height: "calc(100% - 250px)",
            position: "absolute",
            top: "250px",
            left: "50%",
            zIndex: -1,
          },
        }}
      >
        <SectionHeading sx={{ mb: [12, null, null, 28] }}>
          Meet the Team
        </SectionHeading>
        <Container>
          {data.allStrapiAmbassador.edges.map((ambassador, index) => {
            const { slug, intro, name, specialty, intro_image } =
              ambassador.node
            const heading = `${name}, ${specialty}`
            const btnText = `${name?.split(" ")[0]}'s story`

            return (
              <React.Fragment key={slug}>
                {ambassador && (
                  <AmbassadorsInfoBlock
                    heading={heading}
                    buttonText={btnText}
                    buttonUrl={`/ambassadors/${slug}`}
                    content={intro}
                    image={
                      intro_image?.localFile?.childImageSharp?.gatsbyImageData
                    }
                    imageAlt={intro_image?.alternativeText}
                  />
                )}
              </React.Fragment>
            )
          })}
        </Container>
      </Box>
    </Layout>
  )
}

export default AmbassadorsParentPage

export const query = graphql`
  query AmbassadorsParentPage {
    allStrapiAmbassador {
      edges {
        node {
          id
          specialty
          name
          slug
          intro
          intro_image {
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  }
`
