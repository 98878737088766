/** @jsx jsx */
import { StaticImage } from "gatsby-plugin-image"
import { Box, Flex, Container, Heading, Paragraph, jsx } from "theme-ui"

import LineAnimation from "components/common/LineAnimation"
import SectionHeading from "components/SectionHeading"

interface BannerHeroProps {
  //enables sx prop to be passed down from parent
  className?: string
  heading: string
  subHeading?: string
  content?: string
}

const BannerHero = ({
  className,
  heading,
  subHeading,
  content,
}: BannerHeroProps) => {
  return (
    <Box as="section" sx={{ mb: [12, null, 28] }}>
      <Box
        sx={{
          width: "100%",
          background: `linear-gradient(90deg, rgba(255,255,255,0) 50%, #D6D1C4 50%)`,
          py: 12,
          pb: [24, null, 48, null, null],
          position: "relative",
        }}
      >
        <LineAnimation
          variant="primary"
          sx={{ height: ["80%", null, "110%"], top: ["25%", null, 0] }}
        />
        <Box
          sx={{
            width: "full",
            height: "100%",
            position: "relative",
            zIndex: 3,
          }}
        >
          <Flex
            sx={{
              width: "100%",
              justifyContent: "center",
              pl: 4,
              mb: 8,
            }}
          >
            <StaticImage
              src="../images/proven-performance-hero-banner-text.png"
              alt="Proven Performance"
            />
          </Flex>
          <SectionHeading
            variant="hero"
            sx={{
              color: "primary",
              ".titlebox": { maxWidth: ["80%", null, "510px"] },
            }}
          >
            {heading}
          </SectionHeading>
          <Container variant="layout.wide">
            {subHeading && <Heading as="h2">{subHeading}</Heading>}
            <Paragraph
              sx={{ maxWidth: ["100%", null, "580px", null, null], mb: 16 }}
            >
              {content}
            </Paragraph>
          </Container>
        </Box>
      </Box>
    </Box>
  )
}

export default BannerHero
