/** @jsxRuntime classic */
/** @jsx jsx */
import { Box, jsx } from "theme-ui"
import { motion } from "framer-motion"

interface LineAnimationProps {
  //enables sx prop to be passed down from parent
  className?: string
  variant?: "primary" | "secondary" | "thin"
}

const LineAnimation = ({ className, variant="secondary" }: LineAnimationProps) => {
  if (variant === "primary" || variant === "secondary") {
    return (
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 0,
          height: variant === "primary" ? "110%" : "100%",
          overflow: "hidden",
          opacity: [0, null, 1, null, null],
        }}
        className={className}
      >
        <motion.div
          animate={{ x: "140vw" }}
          initial={{ x: "0vw", skew: -20 }}
          transition={{
            duration: 3.6,
            repeat: Infinity,
            ease: [0, 1.12, 1, 0.24],
            delay: 0.5,
            repeatDelay: 2.5,
          }}
          sx={{
            width: ["128px", null, "331px", null, null],
            height: ["6px", null, "16px", null, null],
            backgroundColor: "secondary",
            position: "absolute",
            top: ["51%", null, "32%"],
            left: ["-128px", null, "-400px", null, null],
            zIndex: 0,
          }}
        />
        <motion.div
          animate={{ x: "140vw" }}
          initial={{ x: "0vw", skew: -20 }}
          transition={{
            duration: 3.8,
            repeat: Infinity,
            ease: [0, 1.09, 0.96, 0.24],
            delay: 1,
            repeatDelay: 2.5,
          }}
          sx={{
            width: ["81px", null, "141px", null, null],
            height: ["6px", null, "16px", null, null],
            backgroundColor: "secondary",
            position: "absolute",
            bottom: "38%",
            left: "-145px",
            zIndex: 0,
            opacity: ".5",
          }}
        />
        <motion.div
          animate={{ x: ["0%", "10%", "0%"] }}
          transition={{ duration: 10.0, repeat: Infinity }}
          initial={{ x: "100%", skew: -20 }}
          sx={{
            width: ["189px", null, "494px", null, null],
            height: ["6px", null, "16px", null, null],
            backgroundColor: "secondary",
            position: "absolute",
            bottom: "26%",
            right: "40%",
            zIndex: 0,
            opacity: "1",
          }}
        />
        <motion.div
          animate={{ x: ["0px", "-30px", "0px"] }}
          transition={{ duration: 6, repeat: Infinity }}
          initial={{ x: "100%", skew: -20 }}
          sx={{
            width: ["189px", null, "380px", null, null],
            height: ["6px", null, "16px", null, null],
            backgroundColor: "secondary",
            position: "absolute",
            bottom: "23.5%",
            right: "44%",
            zIndex: 0,
            opacity: ".5",
          }}
        />
        <motion.div
          animate={{ x: ["0%", "-10%", "0%"] }}
          transition={{ duration: 30.0, repeat: Infinity }}
          initial={{ x: "100%", skew: -20 }}
          sx={{
            width: ["189px", null, "382px", null, null],
            height: ["6px", null, "16px", null, null],
            backgroundColor: "secondary",
            position: "absolute",
            bottom: "20%",
            right: "5%",
            zIndex: 0,
            opacity: ".75",
          }}
        />
        <motion.div
          animate={{ x: ["0%", "5%", "0%"] }}
          transition={{ duration: 30.0, repeat: Infinity }}
          initial={{ x: "100%", skew: -20 }}
          sx={{
            width: ["82px", null, "141px", null, null],
            height: ["6px", null, "16px", null, null],
            backgroundColor: "secondary",
            position: "absolute",
            bottom: "18%",
            right: "18%",
            zIndex: 0,
            opacity: ".25",
          }}
        />
        <motion.div
          animate={{ x: "140vw" }}
          initial={{ x: "0vw", skew: -20 }}
          transition={{
            duration: 3.6,
            repeat: Infinity,
            ease: [0, 0.98, 1.12, 0.24],
            delay: 1.5,
            repeatDelay: 2.5,
          }}
          sx={{
            width: ["82px", null, "141px", null, null],
            height: ["6px", null, "16px", null, null],
            backgroundColor: "secondary",
            position: "absolute",
            bottom: "2%",
            left: ["-100px", null, "-150px", null, null],
            zIndex: 0,
            opacity: ".75",
          }}
        />
      </Box>
    )
  }

  if (variant === "thin") {
    return (
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 0,
          height: "100%",
          overflow: "hidden",
          opacity: [0.5, null, 1, null, null],
        }}
        className={className}
      >
        <motion.div
          animate={{ x: "140vw" }}
          initial={{ x: "0vw", skew: -20 }}
          transition={{
            duration: 3.6,
            repeat: Infinity,
            ease: [0, 1.12, 1, 0.24],
            delay: 0.5,
            repeatDelay: 2.5,
          }}
          sx={{
            width: ["128px", null, "331px", null, null],
            height: ["6px", null, "16px", null, null],
            backgroundColor: "text",
            position: "absolute",
            top: ["51%", null, "10%"],
            left: ["-128px", null, "-400px", null, null],
            zIndex: 0,
          }}
        />
        <motion.div
          animate={{ x: "140vw" }}
          initial={{ x: "0vw", skew: -20 }}
          transition={{
            duration: 3.8,
            repeat: Infinity,
            ease: [0, 1.09, 0.96, 0.24],
            delay: 1,
            repeatDelay: 2.5,
          }}
          sx={{
            width: ["81px", null, "141px", null, null],
            height: ["6px", null, "16px", null, null],
            backgroundColor: "secondary",
            position: "absolute",
            bottom: "50%",
            left: "-145px",
            zIndex: 0,
          }}
        />
        <motion.div
          animate={{ x: ["0%", "10%", "0%"] }}
          transition={{ duration: 10.0, repeat: Infinity }}
          initial={{ x: "100%", skew: -20 }}
          sx={{
            width: ["189px", null, "494px", null, null],
            height: ["6px", null, "16px", null, null],
            backgroundColor: "primary",
            position: "absolute",
            bottom: "33%",
            right: "40%",
            zIndex: 0,
          }}
        />
        <motion.div
          animate={{ x: ["0px", "150px", "0px"] }}
          transition={{ duration: 6, repeat: Infinity }}
          initial={{ x: "100%", skew: -20 }}
          sx={{
            width: ["189px", null, "380px", null, null],
            height: ["6px", null, "16px", null, null],
            backgroundColor: "tan",
            position: "absolute",
            bottom: "25%",
            right: "44%",
            zIndex: 0,
          }}
        />
        <motion.div
          animate={{ x: ["0%", "60%", "0%"] }}
          transition={{ duration: 30.0, repeat: Infinity }}
          initial={{ x: "100%", skew: -20 }}
          sx={{
            width: ["189px", null, "382px", null, null],
            height: ["6px", null, "16px", null, null],
            backgroundColor: "primary",
            position: "absolute",
            bottom: "10%",
            right: "5%",
            zIndex: 0,
          }}
        />
        <motion.div
          animate={{ x: "140vw" }}
          initial={{ x: "0vw", skew: -20 }}
          transition={{
            duration: 3.6,
            repeat: Infinity,
            ease: [0, 0.98, 1.12, 0.24],
            delay: 1.5,
            repeatDelay: 2.5,
          }}
          sx={{
            width: ["82px", null, "141px", null, null],
            height: ["6px", null, "16px", null, null],
            backgroundColor: "text",
            position: "absolute",
            bottom: "2%",
            left: ["-100px", null, "-150px", null, null],
            zIndex: 0,
          }}
        />
      </Box>
    )
  }

  return null
}

export default LineAnimation
